// session types
export const RECEIVE_AUTH_TOKEN = 'RECEIVE_AUTH_TOKEN';
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT';
export const RECEIVE_SESSION_LOADING = 'RECEIVE_SESSION_LOADING';
export const RECEIVE_SESSION_ERROR = 'RECEIVE_SESSION_ERROR';
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';

// stream types
export const RECEIVE_STREAM = 'RECEIVE_STREAM';
export const REMOVE_STREAM = 'REMOVE_STREAM';
export const END_STREAM = 'END_STREAM';
export const RECEIVE_PEER_CONNECTION = 'RECEIVE_PEER_CONNECTION';
export const RECEIVE_PARTICIPANT = 'RECEIVE_PARTICIPANT';
export const RECEIVE_FULL_SCREEN = 'RECEIVE_FULL_SCREEN';
export const RECEIVE_STREAM_PENDING = 'RECEIVE_STREAM_PENDING';
export const RECEIVE_STREAM_LOADING = 'RECEIVE_STREAM_LOADING';

// signal types
export const REQUEST_CALL = 'REQUEST_CALL';
export const ANSWER_CALL = 'ANSWER_CALL';
export const JOIN_CALL = 'JOIN_CALL';
export const EXCHANGE = 'EXCHANGE';
export const RECEIVE_NEW_MESSAGE = 'RECEIVE_NEW_MESSAGE';

// user types
export const RECEIVE_USER = "RECEIVE_USER";
export const RECEIVE_USERS = "RECEIVE_USERS";
export const RECEIVE_ONLINE_STATUS = 'RECEIVE_ONLINE_STATUS';
export const RECEIVE_ONLINE_STATUSES = 'RECEIVE_ONLINE_STATUSES';
export const RECEIVE_USER_LOADING = "RECEIVE_USER_LOADING";

// global types
export const RECEIVE_GLOBAL_MESSAGE = 'RECEIVE_GLOBAL_MESSAGE';
export const REMOVE_GLOBAL_MESSAGE = 'REMOVE_GLOBAL_MESSAGE';
export const RECEIVE_GLOBAL_ERROR = 'RECEIVE_GLOBAL_ERROR';
export const REMOVE_GLOBAL_ERROR = 'REMOVE_GLOBAL_ERROR';

// message types
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const RECEIVE_MESSAGES = 'RECEIVE_MESSAGES';
export const RECEIVE_CONVERSATIONS = 'RECEIVE_CONVERSATIONS';
export const RECEIVE_CONVERSATION = 'RECEIVE_CONVERSATION';
export const RECEIVE_ACTIVE_CONVERSATION = 'RECEIVE_ACTIVE_CONVERSATION';
export const RECEIVE_MESSAGE_LOADING = 'RECEIVE_MESSAGE_LOADING';
